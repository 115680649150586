import React, { ReactElement } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import {
  Box, Button, CircularProgress, FormControl, IconButton, MenuItem, Select, TextField,
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import { Delete, Close } from '@material-ui/icons';
import {
  Chat, CreateGptResponse, CreateLLMQuestionResponse, LLMResponse, SuggestionEntity, UserFeedbackRequest,
} from '../../queries/gpt/types';
import GptDataGrid from './GptDataGrid';
import {
  useGetUserChats, useGetChatQuestions, useGetAIModels,
} from '../../queries/gpt';
import AutoCompleteTextFieldProps from './AutoCompleteTextField';
import Modal from '../modals/Modal/Modal';
import { formatDateUSReadable } from '../../formatters/dateFormatters/dateFormatters';
import {
  useAskGptMutation, useCreateChatMutation, useDeleteChatMutation, useDeleteQuestionMutation, useUserFeedbackMutation,
} from '../../queries/gpt/mutations';
import useDeleteModal from '../modals/DeleteModal/hooks';
import LLMQuestion from './LLMQuestion';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: 'calc(100vh - 200px)',
    width: '760px',
  },
  responseView: {
    padding: theme.spacing(2),
    height: 'calc(100vh - 266px)',
    backgroundColor: '#fff',
    overflow: 'auto',
  },
  inputText: {
    backgroundColor: '#fff',
    height: '56px',
    display: 'flex',
    alignItems: 'center',
  },
  textField: {
    flexGrow: 1,
    width: '100%',
  },
  buttonBox: {
    height: '100%',
    position: 'relative',
  },
  sendButton: {
    height: '100%',
  },
  aiModelSwitch: {
    position: 'absolute',
    top: '3px',
    right: '50px',
    width: '250px',
    textAlign: 'center',
  },
  tipsButton: {
    float: 'right',
    marginTop: '5px',
  },
  feedBackContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  feedbackButton: {
    padding: '0px',
    width: '18px !important',
    borderRadius: '5px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#CCC',
      color: '#000',
    },
  },
  chatBox: {
    borderRight: '1px solid #a79a9a',
    height: 'calc(100vh - 266px)',
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  chat: {
    position: 'relative',
  },
  chatDate: {
    position: 'absolute',
    top: '23px',
    left: '10px',
    fontSize: '10px',
  },
  deleteChat: {
    width: '25px',
    position: 'absolute',
    top: '5px',
    right: '0px',
    '&:hover': {
      color: '#000',
    },
  },
  questionBox: {
    backgroundColor: '#CCC',
    padding: '10px',
  },
  answerBox: {
    backgroundColor: '#f1f1f1',
    padding: '10px',
  },
  answerContainer: {
    marginBottom: '10px',
    position: 'relative',
    '&:hover': {
      boxShadow: '0px 0px 5px 0px #CCC',
    },
  },
  closeButton: {
    position: 'absolute',
    top: '8px',
    right: '10px',
    '&:hover': {
      color: '#000',
    },
  },
}));

const GptUI: () => React.ReactElement = (): ReactElement => {
  const classes = useStyles();
  const [question, setQuestion] = React.useState('');
  const [selectedSuggestions, setSelectedSuggestions] = React.useState<SuggestionEntity[]>([]);
  const [canSend, setCanSend] = React.useState(false);
  const [initialData, setData] = React.useState< LLMResponse[] >([]);
  const [aiModel, setAiModel] = React.useState('');
  const [openTipsModal, setOpenTipsModal] = React.useState(false);
  const [currentChat, setCurrentChat] = React.useState<Chat>();
  const [chats, setChats] = React.useState<Chat[]>([]);
  const [feedbackType, setFeedbackType] = React.useState<number>(0);
  const [openFeedbackModal, setOpenFeedbackModal] = React.useState(false);
  const [feedbackMessage, setFeedbackMessage] = React.useState<string>('');
  const [feedbackAnswerId, setFeedbackAnswerId] = React.useState<number>(0);
  const [mouseOverAnswer, setMouseOverAnswer] = React.useState<number>(0);
  const { data: aiModels } = useGetAIModels();
  const {
    showModal: showDeleteQuestionModal, RenderModal: DeleteQuestionModal, modalId: questionModalId,
  } = useDeleteModal();

  const {
    showModal: showDeleteChatModal, RenderModal: DeleteChatModal, modalId: chatModalId,
  } = useDeleteModal();

  const resetAfterAskQuestion = () => {
    setCanSend(false);
    setQuestion('');
    setSelectedSuggestions([]);
  };

  const { data: userQuestions } = useGetChatQuestions(currentChat);
  const { data: userChats } = useGetUserChats();
  const askGPTMutation = useAskGptMutation();
  const createChat = useCreateChatMutation();
  const userFeedbackMutation = useUserFeedbackMutation();
  const deleteChat = useDeleteChatMutation();
  const deleteQuestion = useDeleteQuestionMutation();

  React.useEffect(() => {
    if (aiModels && aiModel === '') {
      setAiModel(aiModels[0].model);
    }
  }, [aiModels]);

  React.useEffect(() => {
    if (userQuestions) {
      setData(userQuestions);
      if (userQuestions.length > 0) {
        setAiModel(userQuestions[userQuestions.length - 1].type);
      }
    }
  }, [userQuestions]);

  const resetAfterFeedback = () => {
    setOpenFeedbackModal(false);
    setFeedbackMessage('');
    setFeedbackAnswerId(0);
  };

  const handleAskGpt = async () => {
    try {
      if (!currentChat) {
        return;
      }
      setCanSend(true);
      const request = {
        question,
        chat_id: currentChat.id,
        selectedSuggestions,
        ai_model: aiModel,
      };
      await askGPTMutation.mutateAsync({ request });
      resetAfterAskQuestion();
    } catch (error) {
      resetAfterAskQuestion();
    }
  };

  const handleCreateChat = async () => {
    try {
      const request = {
        name: `Chat-${Math.floor(Math.random() * 1000000)}`,
      };

      await createChat.mutateAsync(request);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  const handleDeleteChat = async (chatId: number | null) => {
    if (chatId) {
      deleteChat.mutateAsync({ chatId });
    }
  };

  const handleDeleteQuestion = async (questionId: number | null) => {
    const chatId = currentChat?.id;
    if (chatId && questionId) {
      deleteQuestion.mutateAsync({ questionId, chatId });
    }
  };

  React.useEffect(() => {
    if (userChats) {
      setChats(userChats);
      if (userChats.length > 0) {
        setCurrentChat(userChats[0]);
      } else {
        handleCreateChat();
      }
    }
  }, [userChats]);

  const handleFeedbackSubmit = async () => {
    try {
      const request: UserFeedbackRequest = {
        feedback: feedbackType,
        feedbackComment: feedbackMessage,
      };
      await userFeedbackMutation.mutateAsync(
        { request, question_id: feedbackAnswerId, chat_id: currentChat?.id },
      );
      resetAfterFeedback();
    } catch (error) {
      resetAfterFeedback();
    }
  };

  const handleFeedback = (feedback: number, answer: CreateGptResponse) => {
    setOpenFeedbackModal(true);
    setFeedbackType(feedback);
    setFeedbackAnswerId(answer.id);
    if (answer.feedback && answer.feedback === feedback) {
      setFeedbackMessage(answer.feedbackComment || '');
    }
  };

  const handelDropDownChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setAiModel(event.target.value as string);
  };

  const handleResponseType = (response: LLMResponse) => {
    if ('answer' in response) {
      const data = response as CreateGptResponse;
      return (
        <Box className={classes.answerBox}>
          { data.errorMessage ? (
            <Box>
              <span style={{ color: 'red' }}>{data.errorMessage}</span>
            </Box>
          )
            : data.answerType !== 'TABLE' ? (
              <Box>{data.answer}</Box>
            ) : (
              <Box>
                { (typeof data.answer !== 'string') ? (
                  <GptDataGrid
                    columns={data.answer.columns}
                    data={data.answer.data}
                    index={data.answer.index}
                  />
                ) : null }
              </Box>
            )}
          <Box className={classes.feedBackContainer}>
            <ThumbDownOffAltIcon
              color={data.feedback !== undefined && data.feedback === 2 ? 'error' : 'disabled'}
              className={classes.feedbackButton}
              onClick={() => handleFeedback(2, data)}
            />
            <ThumbUpOffAltIcon
              color={data.feedback !== undefined && data.feedback === 1 ? 'success' : 'disabled'}
              className={classes.feedbackButton}
              onClick={() => handleFeedback(1, data)}
            />
          </Box>
        </Box>
      );
    }
    const data = response as CreateLLMQuestionResponse;
    return (<LLMQuestion setInputValue={setQuestion} question={data} />);
  };

  return (
    <Box className={classes.root}>
      <Grid container>
        <Grid item xs={2} sm={2} className={classes.chatBox}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleCreateChat}
          >
            New Chat
          </Button>
          {chats.map((chat) => (
            <Box key={chat.id} className={classes.chat}>
              <Button
                color={currentChat?.id === chat.id ? 'primary' : 'default'}
                onClick={() => setCurrentChat(chat)}
              >
                {chat.name}
              </Button>
              <IconButton
                style={{ display: currentChat?.id === chat.id ? 'block' : 'none' }}
                size="small"
                className={classes.deleteChat}
                aria-label="delete"
                onClick={(e) => {
                  e.stopPropagation();
                  showDeleteChatModal(chat.id);
                }}
              >
                <Delete fontSize="small" />
              </IconButton>
              <Box className={classes.chatDate}>
                {formatDateUSReadable(chat.createdAt)}
              </Box>
            </Box>
          ))}
        </Grid>
        <Grid item xs={10} sm={10}>
          <Box className={classes.aiModelSwitch}>
            <IconButton
              size="small"
              className={classes.tipsButton}
              onClick={() => setOpenTipsModal(true)}
            >
              <TipsAndUpdatesIcon />
            </IconButton>
            <FormControl margin="none" style={{ width: '200px' }}>
              <Select
                value={aiModel}
                onChange={handelDropDownChange}
              >
                { aiModels?.map((model) => (
                  <MenuItem
                    key={model.model}
                    disabled={!model.active}
                    value={model.model}
                  >
                    {model.model}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <Box className={classes.responseView}>
                {/* Response view content goes here */}
                {initialData.map((data) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <>
                    <Box
                      className={classes.answerContainer}
                      key={data.id}
                      onMouseEnter={() => setMouseOverAnswer(data.id)}
                      onMouseLeave={() => setMouseOverAnswer(0)}
                    >
                      <IconButton
                        style={{ display: mouseOverAnswer === data.id ? 'flex' : 'none' }}
                        size="small"
                        className={classes.closeButton}
                        aria-label="delete"
                        onClick={(e) => {
                          e.stopPropagation();
                          showDeleteQuestionModal(data.id);
                        }}
                      >
                        <Close fontSize="small" />
                      </IconButton>
                      <Box className={classes.questionBox}>{data.question}</Box>
                      {handleResponseType(data)}
                    </Box>
                  </>
                ))}
              </Box>
              <Paper className={classes.inputText}>
                {/* Input text content goes here */}
                <AutoCompleteTextFieldProps
                  inputValue={question}
                  setInputValue={setQuestion}
                  selectedSuggestions={selectedSuggestions}
                  setSelectedSuggestions={setSelectedSuggestions}
                />
                <Box className={classes.buttonBox}>
                  <Button
                    className={classes.sendButton}
                    variant="contained"
                    color="primary"
                    onClick={(e) => {
                      e.stopPropagation();
                      if (question.trim() !== '') {
                        handleAskGpt();
                      }
                    }}
                    disabled={canSend}
                  >
                    Send
                  </Button>
                  <CircularProgress
                    size={24}
                    style={{
                      color: green[500],
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: '-12px',
                      marginLeft: '-12px',
                      visibility: canSend ? 'visible' : 'hidden',
                    }}
                  />
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Modal
        confirmColor="secondary"
        title="Tips for Using Our Chatbot:"
        body={(
          <Box style={{ overflowY: 'hidden' }}>
            <Box>
              <Box>
                <b>Usage:</b>
              </Box>
              <Box>
                <ul>
                  <li>New chat for new topic</li>
                  <li>Use the autocomplete for better results</li>
                  <li>Use drop down to switch between language models if you not receiving expected results</li>
                  <li>Use the feedback buttons to improve the chatbot in the future</li>
                </ul>
              </Box>
            </Box>
            <Box>
              <Box>
                <b>Answers:</b>
              </Box>
              <Box>
                <ul>
                  <li>Chatbot doesn&apos;t respond to questions that are not relevant to Leasing Data</li>
                  <li>You will receive better results if the prompt is detailed</li>
                  <li>
                    By default, you&apos;ll receive the top 5 results. If you specify more,
                    the chatbot will adjust accordingly.
                  </li>
                </ul>
              </Box>
            </Box>
          </Box>
        )}
        isOpen={openTipsModal}
        handleClose={() => { setOpenTipsModal(false); }}
        cancelText="Close"
        cancelAction={() => { setOpenTipsModal(false); }}
      />
      <Modal
        confirmColor="secondary"
        title={
          (
            <Box style={{ display: 'flex' }}>
              <Box>
                {(feedbackType === 1
                  ? <ThumbUpOffAltIcon />
                  : <ThumbDownOffAltIcon />
                )}
              </Box>
              <Box>Provide Feedback</Box>
            </Box>
          )
        }
        body={(
          <Box style={{ overflowY: 'hidden' }}>
            <TextField
              value={feedbackMessage}
              variant="outlined"
              label={(feedbackType === 1
                ? 'Add your feedback'
                : 'What was the issue with the response? How can it be improved?')}
              style={{ width: '540px', marginTop: '10px' }}
              onChange={(e) => setFeedbackMessage(e.target.value)}
            />
          </Box>
        )}
        isOpen={openFeedbackModal}
        handleClose={() => { setOpenFeedbackModal(false); setFeedbackMessage(''); }}
        confirmText="Submit feedback"
        confirmAction={() => handleFeedbackSubmit()}
        cancelText="Cancel"
        cancelAction={() => { setOpenFeedbackModal(false); setFeedbackMessage(''); }}
      />
      <DeleteQuestionModal
        body="Are you sure you want to delete this question?"
        confirmAction={() => handleDeleteQuestion(questionModalId)}
        title="Delete Question"
      />
      <DeleteChatModal
        body="Are you sure you want to delete this chat?"
        confirmAction={() => handleDeleteChat(chatModalId)}
        title="Delete Chat"
      />
    </Box>
  );
};

export default GptUI;
